import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Box from '../box/box.jsx';

import enterUpdateIcon from './icons/enter-update.svg';

import styles from './connection-modal.css';
import Label from '../forms/label.jsx';
import radarIcon from './icons/check.png';
import failIcon from './icons/remove.png';

const UpdateContent = props => {
    return (<Box className={styles.body}>
        <Box className={styles.activityArea}>
            <div className={styles.activityAreaInfo}>
                {props.isUploadSuccess ? <div className={styles.centeredRow}>
                    <img
                        className={classNames(styles.radarSmall)}
                        src={radarIcon}
                    />
                    <div className={styles.centeredRow}>
                        <Label>Your Megaton board has been up to date !</Label>
                    </div>
                </div> : <div className={styles.centeredRow}>
                    <img
                        className={classNames(styles.radarSmall)}
                        src={failIcon}
                    />
                    <div className={styles.centeredRow}>
                        <Label>Update Proccess Fail. Make sure you remove the connectors and connect your Megaton board with cable </Label>
                    </div>
                </div>}
            </div>
        </Box>
        <Box className={styles.bottomArea}>
            <Box className={classNames(styles.bottomAreaItem, styles.buttonRow)}>
                <button
                    className={styles.connectionButton}
                    onClick={props.onCloseStatusModal}
                >
                    <FormattedMessage
                        defaultMessage="Close"
                        description="Button to enter the peripheral update mode"
                        id="gui.megatonmodal.connection.scanning.updatePeripheralButton"
                    />
                </button>
            </Box>
        </Box>
    </Box>);
};

UpdateContent.propTypes = {
    onUpdatePeripheral: PropTypes.func,
    isUploading: PropTypes.bool.isRequired,
    isUploadSuccess: PropTypes.bool.isRequired,
    onCloseStatusModal: PropTypes.func
};

export default UpdateContent;
