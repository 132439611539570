import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Box from '../box/box.jsx';

import enterUpdateIcon from './icons/enter-update.svg';

import styles from './connection-modal.css';
import Label from '../forms/label.jsx';
import radarIcon from './icons/searching.png';

const ScanningStep = props => {
    return (<Box className={styles.body}>
        <Box className={styles.activityArea}>
            <div className={styles.activityAreaInfo}>
            {props.isUploading ? <div className={styles.centeredRow}>
                <img
                    className={classNames(styles.radarSmall, styles.radarSpin)}
                    src={radarIcon}
                />
                <div className={styles.centeredRow}>
                    <Label>New Firmware is uploading to your board ...</Label>
                </div>
            </div> : <div className={styles.centeredRow}>
                    <Label>Update Your Firmware Now !</Label>
                </div>}
            </div>
        </Box>
        <Box className={styles.bottomArea}>
            <Box className={classNames(styles.bottomAreaItem, styles.instructions)}>
                    <FormattedMessage
                        defaultMessage="Update your Firmware for newer version"
                        description="Prompt for choosing a device to connect to"
                        id="gui.megatonmodal.connection.scanning.instructions"
                    />
            </Box>
            <Box className={classNames(styles.bottomAreaItem, styles.buttonRow)}>
                <button
                    className={styles.connectionButton}
                    onClick={props.onUpdatePeripheral}
                >
                    <FormattedMessage
                        defaultMessage="Update my Device"
                        description="Button to enter the peripheral update mode"
                        id="gui.megatonmodal.connection.scanning.updatePeripheralButton"
                    />
                    <img
                        className={styles.buttonIconRight}
                        src={enterUpdateIcon}
                    />
                </button>
            </Box>
        </Box>
    </Box>);
};

ScanningStep.propTypes = {
    onUpdatePeripheral: PropTypes.func,
    isUploading: PropTypes.bool.isRequired
};

export default ScanningStep;
