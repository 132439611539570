const Avrgirl = require('avrgirl-arduino');

const avrgirl = new Avrgirl({
    board: 'uno',
});

function flashMegatonFirmware(hexFilePath, callback) {
    // Assume hexFilePath is the path to the firmware hex file
    fetch(hexFilePath)
        .then(response => response.text())
        .then(hexFileContent => {
            console.log(Buffer.from(hexFileContent));
            avrgirl.flash(Buffer.from(hexFileContent), error => {
                if (error) {
                    console.error(error);
                    callback(error, null);
                } else {
                    console.info('Firmware flashed successfully.');
                    callback(null, 'Firmware flashed successfully');
                }
            });
        })
        .catch(error => {
            console.error('Error reading hex file:', error);
            callback(error, null);
        });
}

module.exports = {
    flashMegatonFirmware,
};