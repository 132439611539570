import React from "react";
import './update-modal.css';
import { openFirmwareNotice } from "../../reducers/mode";

const UpdateModalTest = ({onCloseFirmwareModal, onOpenConnectionModal}) => {
    return <div style={{
        width: 'calc(100vw / 4)',
        position: 'absolute',
        zIndex: 9999,
        top: '30%', /* Move it down by 50% of viewport height */
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '20px'
        }}>
        <div style={{
            width: '100%',
            backgroundColor: '#FF0000',
            paddingTop: '10px',
            paddingBottom: '10px',
            borderTopRightRadius: '20px',
            borderTopLeftRadius: '20px',
            borderWidth: '2px',
            borderColor: '#000000'
        }}>
            <label style={{
                fontSize: '20px',
                padding: '10px',
                color: '#FFFFFF',
                
            }}>Notice</label>
        </div>
        <div style={{
            backgroundColor: '#D1FFBD',
            borderBottomRightRadius: '20px',
            borderBottomLeftRadius: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '20px',
            paddingBottom: '20px'
        }}>
            <div>
                <label>New Megaton Firmware is now available. Press Upgrade to Update Firmware for your Megaton Board</label>
            </div>
            <div style={{
                paddingTop: '20px',
                display: 'flex',
                justifyContent: 'center'
            }}>
                <div style={{
                    margin: '0 10px'
                }}>
                    <button style={{
                        backgroundColor: '#FF0000',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '10px',
                        color: '#FFFFFF',
                        ':hover': {
                            backgroundColor: '#FF3333', // Change background color on hover
                        }
                    }} onClick={() => {
                        onCloseFirmwareModal()
                        onOpenConnectionModal()
                        
                    }}>Upgrade Now</button>
                </div>
                <div style={{
                    margin: '0 10px'
                }}>
                    <button style={{
                        backgroundColor: '#FF0000',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '10px',
                        color: '#FFFFFF'
                    }} onClick={() => {
                        onCloseFirmwareModal()
                    }}>Later</button>
                </div>
            </div>
        </div>
        
    </div>
}

export default UpdateModalTest;