import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import VM from 'scratch-vm';
import {flashMegatonFirmware} from './firmware-update-func/megaton-firmware-update';
import {setUploadingStatus, setOpenStatusModal} from '../reducers/mode'


import {connect} from 'react-redux';
import UpdateContent from '../components/connection-modal/update-status-content.jsx';

class UpdateStatusOption extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'updateMegatonFirmware',
            'handleCloseModal'
        ]);
        this.state = {
            scanning: true,
            peripheralList: []
        };
    }
    componentDidMount () {
        this.props.vm.scanForPeripheral(this.props.extensionId);
    }
    componentWillUnmount () {
    }
    updateMegatonFirmware () {
        this.props.onStartUploadFirmware()
        flashMegatonFirmware('https://test-client-stem.s3.amazonaws.com/megaton-firmware/sb-arduino-fw.ino.with_bootloader.hex', (error, result) => {
            if (error) {
                // Handle error
                console.error(error);
            } else {
                // Handle success
                console.log(result);
            }
            this.props.onStopUploadFirmware();
        });
    }
    handleCloseModal () {
        this.props.onCloseStatusModal();
    }
    render () {
        return (
            <UpdateContent
                connectionSmallIconURL={this.props.connectionSmallIconURL}
                peripheralList={this.state.peripheralList}
                phase={this.state.phase}
                scanning={this.state.scanning}
                title={this.props.extensionId}
                onConnected={this.props.onConnected}
                onConnecting={this.props.onConnecting}
                onCloseStatusModal={this.handleCloseModal}
                isUploading={this.props.isUploading}
                isUploadSuccess={this.props.isUploadSuccess}
                onUpdatePeripheral={this.updateMegatonFirmware}
            />
        );
    }
}

UpdateStatusOption.propTypes = {
    connectionSmallIconURL: PropTypes.string,
    extensionId: PropTypes.string.isRequired,
    onConnected: PropTypes.func.isRequired,
    onConnecting: PropTypes.func.isRequired,
    onStartUploadFirmware: PropTypes.func.isRequired,
    onStopUploadFirmware: PropTypes.func.isRequired,
    onUpdatePeripheral: PropTypes.func,
    isUploading: PropTypes.bool.isRequired,
    isUploadSuccess: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({
    isUploadSuccess: state.scratchGui.mode.isUploadSuccess
})

const mapDispatchToProps = dispatch => ({
    onStartUploadFirmware: () => dispatch(setUploadingStatus(true)),
    onStopUploadFirmware: () => dispatch(setUploadingStatus(false)),
    onCloseStatusModal: () => dispatch(setOpenStatusModal(false))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateStatusOption);
