const SET_FULL_SCREEN = 'scratch-gui/mode/SET_FULL_SCREEN';
const SET_PLAYER = 'scratch-gui/mode/SET_PLAYER';
const SET_CAMERA = 'scratch-gui/mode/SET_CAMERA';
const SET_UPLOADING_STATUS = 'scratch-gui/mode/SET_UPLOADING_STATUS';
const SET_UPLOAD_SUCCESS = 'scratch-gui/mode/SET_UPLOAD_SUCCESS';
const OPEN_STATUS_MODAL = 'scratch-gui/mode/OPEN_STATUS_MODAL';
const OPEN_FIRMWARE_NOTICE = 'scratch-gui/mode/OPEN_FIRMWARE_NOTICE';
const CLOSE_FIRMWARE_NOTICE = 'scratch-gui/mode/CLOSE_FIRMWARE_NOTICE'

const initialState = {
    showBranding: false,
    isFullScreen: false,
    isPlayerOnly: false,
    hasEverEnteredEditor: true,
    isOpenCamera: false,
    isUploading: false,
    isUploadSuccess: false,
    isOpenStatusModal: false,
    isOpenFirmwareNotice: (localStorage.megaton_ver_20240607) ? false : true,
};

const reducer = function(state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_FULL_SCREEN:
            return Object.assign({}, state, {
                isFullScreen: action.isFullScreen
            });
        case SET_CAMERA:
            return Object.assign({}, state, {
                isOpenCamera: action.isOpenCamera
            })
        case SET_PLAYER:
            return Object.assign({}, state, {
                isPlayerOnly: action.isPlayerOnly,
                hasEverEnteredEditor: state.hasEverEnteredEditor || !action.isPlayerOnly
            });
        case SET_UPLOADING_STATUS:
            return Object.assign({}, state, {
                isUploading: action.isUploading
            })
        case SET_UPLOAD_SUCCESS:
            return Object.assign({}, state, {
                isUploadSuccess: action.isUploadSuccess
            })
        case OPEN_STATUS_MODAL:
            return Object.assign({}, state, {
                isOpenStatusModal: action.isOpenStatusModal
            })
        case OPEN_FIRMWARE_NOTICE:
            console.log("SET HERE " + action.isOpenFirmwareNotice);
            return Object.assign({}, state, {
                isOpenFirmwareNotice: action.isOpenFirmwareNotice
            })
        default:
            return state;
    }
};


const setFullScreen = function(isFullScreen) {
    return {
        type: SET_FULL_SCREEN,
        isFullScreen: isFullScreen
    };
};
const setPlayer = function(isPlayerOnly) {
    return {
        type: SET_PLAYER,
        isPlayerOnly: isPlayerOnly
    };
};

const setCamera = function(isOpenCamera) {
    console.log("On Press Setting Camera: " + isOpenCamera);

    return {
        type: SET_CAMERA,
        isOpenCamera: isOpenCamera
    }
}

const setUploadingStatus = function(isUploading) {
    console.log("This call " + isUploading)
    return {
        type: SET_UPLOADING_STATUS,
        isUploading: isUploading
    }
}

const setUploadStatus = function(isUploadSuccess) {
    return {
        type: SET_UPLOAD_SUCCESS,
        isUploadSuccess: isUploadSuccess
    }
}

const setOpenStatusModal = function(isOpenStatusModal) {
    return {
        type: OPEN_STATUS_MODAL,
        isOpenStatusModal: isOpenStatusModal
    }
}

const openFirmwareNotice = function(isOpenFirmwareNotice) {
    console.log('click here' + isOpenFirmwareNotice);
    return {
        type: OPEN_FIRMWARE_NOTICE,
        isOpenFirmwareNotice: isOpenFirmwareNotice
    }
}

export {
    reducer as
    default,
    initialState as modeInitialState,
    setFullScreen,
    setPlayer,
    setCamera,
    setUploadingStatus,
    setUploadStatus,
    setOpenStatusModal,
    openFirmwareNotice
};