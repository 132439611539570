import PropTypes from 'prop-types';
import React from 'react';
import keyMirror from 'keymirror';

import Box from '../box/box.jsx';
import Modal from '../../containers/modal.jsx';

import ScanningStep from '../../containers/scanning-step.jsx';

import styles from './connection-modal.css';
import UpdateStatusOption from '../../containers/update-status-option.jsx';

const PHASES = keyMirror({
    scanning: null,
    connecting: null,
    connected: null,
    error: null,
    unavailable: null,
    updatePeripheral: null
});

const UpdateStatusModalComponent = props => (
    <Modal
        className={styles.modalContent}
        contentLabel={props.name}
        headerClassName={styles.header}
        id="updateStatusModal"
        onHelp={props.onHelp}
        onCancel={props.onCancel}
    >
        <Box className={styles.body}>
            <UpdateStatusOption {...props} />
        </Box>
    </Modal>
);

UpdateStatusModalComponent.propTypes = {
    connectingMessage: PropTypes.node.isRequired,
    connectionSmallIconURL: PropTypes.string,
    connectionTipIconURL: PropTypes.string,
    name: PropTypes.node,
    onCancel: PropTypes.func.isRequired,
    onHelp: PropTypes.func.isRequired,
    phase: PropTypes.oneOf(Object.keys(PHASES)).isRequired,
    title: PropTypes.string.isRequired,
    useAutoScan: PropTypes.bool.isRequired,
    isUploading: PropTypes.bool.isRequired
};

UpdateStatusModalComponent.defaultProps = {
    connectingMessage: 'Connecting'
};

export {
    UpdateStatusModalComponent as default,
    PHASES
};
