exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".update-modal_modal-content_FFKxl {\n    width: calc(100vw / 3); /* Set width to one-third of viewport width */\n    line-height: 1.75;\n    position: fixed; /* Use fixed position */\n    top: 50%; /* Move it down by 50% of viewport height */\n    left: 50%; /* Move it to the right by 50% of viewport width */\n    -webkit-transform: translate(-50%, -50%);\n        -ms-transform: translate(-50%, -50%);\n            transform: translate(-50%, -50%); /* Center the modal both horizontally and vertically */\n    background-color: white; /* Optional: Set background color */\n    border: 1px solid black; /* Optional: Add border */\n    padding: 20px; /* Optional: Add padding */\n}", ""]);

// exports
exports.locals = {
	"modal-content": "update-modal_modal-content_FFKxl",
	"modalContent": "update-modal_modal-content_FFKxl"
};